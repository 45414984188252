import LoginHeader from './LoginHeader';
import MainHeader from './MainHeader';
import Auth from '../../utils/auth';

const Header = () => {

  if (!Auth.loggedIn()) {
    return (
      <LoginHeader />
    )
  } else {
    return (
      <MainHeader />
    )
  };
}

export default Header;
