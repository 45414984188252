import { useLocation, useNavigate } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="w-100 p-0.2 footer">
      <div className="container text-center mb-5">

      </div>
    </footer>
  );
};

export default Footer;
