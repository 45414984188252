import { gql } from '@apollo/client';

export const QUERY_USER = gql`
  query getUser($userId: ID!) {
    getUser(userId: $userId) {
      _id
      username
      email
      mobile
      firstname
      lastname
      wallet
      virtualWallet
      maxWallet
      minWallet
      maxVirtualWallet
      minVirtualWallet
      lastLogin
      addresses {
        _id
        userId
        addressType
        address1
        address2
        city
        state
        postalCode
        country
      }
      billingIsStreet
    }
  }
`;

export const QUERY_SYSTEMS = gql`
  query getSystems ($userId: ID!, $isActive: Boolean,  $sortName: String, $sortType: Int, $showArchived: Boolean) {
    getSystems(userId: $userId, isActive: $isActive, sortName: $sortName, sortType: $sortType, showArchived: $showArchived) {
      _id
      userId
      systemId
      isActive
      isArchived
      isPaused
      isStarting
      title
      description
      isRacingEvent
      scenario {
        _id
        title
        description
        back
        lay
        params
        formName
      }
      stakingPlan {
        _id
        title
        description
        back
        lay
        params
        formName
      }
      scenarioParams
      stakingParams
      mode
      apiMode
      betType
      racingBetType
      raceNumbers
      raceDistance
      raceClass
      matchInPlay
      maxEvents
      maxMarkets
      minOdds
      maxOdds
      maxRunners
      minRunners
      maxBackLayRatio
      maxLiability
      maxBets
      minBets
      minMatched
      maxBookBackBets
      minBookLayBets
      minPlaceWinners
      maxPlaceWinners
      stopLoss
      stopProfit
      maxLosers
      maxWinners
      consecLosers
      consecWinners
      betStartingPrice
      unsettledLimit
      includeCommission
      timeSecsRace
      nthFavourite
      numFavourites
      exclFavourite
      totalEvents
      totalMarkets
      totalBets
      profitLoss
      totalLosers
      totalWinners
      totalConsecLosers
      totalConsecWinners
      unsettledBets
      maxBet
      textQuery
      eventTypeId
      competitionIds
      marketIds
      venues
      bspOnly
      turnInPlayEnabled
      inPlayOnly
      marketBettingTypes
      marketCountries
      marketTypeCodes
      customTime
      marketFromTime
      marketToTime
      withOrders
      raceTypes
      sort
      maxResults
      marketProjection
      lastStarted
      lastStopped
      lastReset
      statusDesc
      ordering
      eventCount
      marketCount
    }
  }
`;

export const QUERY_SYSTEM = gql`
  query getSystem($userId: ID!, $systemId: ID!) {
    getSystem(userId: $userId, systemId: $systemId) {
      _id
      userId
      systemId
      isActive
      isArchived
      isPaused
      isStarting
      title
      description
      isRacingEvent
      scenario {
        _id
        scenarioId
        title
        description
        back
        lay
        params
        formName
      }
      stakingPlan {
        _id
        stakingId
        title
        description
        back
        lay
        params
        formName
      }
      scenarioParams
      stakingParams
      mode
      apiMode
      betType
      racingBetType
      raceNumbers
      raceDistance
      raceClass
      matchInPlay
      maxEvents
      maxMarkets
      minOdds
      maxOdds
      maxRunners
      minRunners
      maxBackLayRatio
      maxLiability
      maxBets
      minBets
      minMatched
      maxBookBackBets
      minBookLayBets
      minPlaceWinners
      maxPlaceWinners
      stopLoss
      stopProfit
      maxLosers
      maxWinners
      consecLosers
      consecWinners
      betStartingPrice
      unsettledLimit
      includeCommission
      timeSecsRace
      nthFavourite
      numFavourites
      exclFavourite
      totalEvents
      totalMarkets
      totalBets
      profitLoss
      totalLosers
      totalWinners
      totalConsecLosers
      maxConsecLosers
      totalConsecWinners
      maxConsecWinners
      lossStopOption
      profitStopOption
      totalBetsOption
      totalEventsOption
      totalMarketsOption
      maxWinnersOption
      maxLosersOption
      consecWinnersOption
      consecLosersOption
      unsettledBets
      maxBet
      textQuery
      eventTypeId
      competitionIds
      marketIds
      venues
      bspOnly
      turnInPlayEnabled
      inPlayOnly
      marketBettingTypes
      marketCountries
      marketTypeCodes
      customTime
      marketFromTime
      marketToTime
      withOrders
      raceTypes
      sort
      maxResults
      marketProjection
      lastStarted
      lastStopped
      lastReset
      lastEventUpdate
      lastBetMade
      lastBetMadeBets
      lastBetUpdate
      lastBetUpdateBets
      statusDesc
      ordering
      eventCount
      marketCount
      systemMessages
    }
  }
`;

export const QUERY_SYSTEM_AG = gql`
  query  getSystemAg($userId: ID!) {
    getSystemAg(userId: $userId) {
      _id
      sum_events
      sum_markets
      sum_bets
      sum_winners
      sum_losers
      sum_unsettled
      sum_profitloss
    }
  }
`;

export const QUERY_EVENTS = gql`
  query getEvents ($userId: ID!, $systemId: ID! ) {
    getEvents(userId: $userId, systemId: $systemId) {
      _id
      systemId
    eventId
    eventName
    eventTypeId
    countryCode
    timezone
    venue
    openDate
    marketCount
    include
    markets {
      _id
      systemId
      marketId
      marketName
      eventId
      eventName
      marketStartTime
      totalMatched
      competition
      raceNumber
      raceDistance
      raceClass
      persistenceEnabled
      bspMarket
      marketTime
      suspendTime
      settleTime
      bettingType
      turnInPlayEnabled
      marketType
      regulator
      marketBaseRate
      discountAllowed
      wallet
      rules
      rulesHasDate
      eachWayDivisor
      clarifications
      lineRangeInfo
      raceType
      priceLadderDescription
      isMarketDataDelayed
      status
      betDelay
      bspReconciled
      complete
      inplay
      numberOfWinners
      numberOfRunners
      numberOfActiveRunners
      lastMatchTime
      totalAvailable
      crossMatching
      runnersVoidable
      version
      include
    }
    }
  }
`;

export const QUERY_MARKETS = gql`
  query getMarkets($userId: ID!, $systemId: ID!, $marketId: String!, $type: String) {
    getMarkets(userId: $userId, systemId: $systemId, marketId: $marketId, type: $type) {
      _id
      systemId
      marketId
      marketName
      eventId
      eventName
      marketStartTime
      totalMatched
      competition
      raceNumber
      raceDistance
      raceClass
      persistenceEnabled
      bspMarket
      marketTime
      suspendTime
      settleTime
      bettingType
      turnInPlayEnabled
      marketType
      regulator
      marketBaseRate
      discountAllowed
      wallet
      rules
      rulesHasDate
      eachWayDivisor
      clarifications
      lineRangeInfo
      raceType
      priceLadderDescription
      isMarketDataDelayed
      status
      betDelay
      bspReconciled
      complete
      inplay
      numberOfWinners
      numberOfRunners
      numberOfActiveRunners
      lastMatchTime
      totalAvailable
      crossMatching
      runnersVoidable
      version
      include
      runners {
        _id
        systemId
        marketId
        selectionId
        runnerName
        handicap
        sortPriority
        metadata
        status
        adjustmentFactor
        lastPriceTraded
        totalMatched
        removalDate
        spNearPrice
        spFarPrice
        spBackStakeTaken
        spLayLiabilityTaken
        actualSP
        exAvailableToBack
        exAvailableToLay
        exTradedVolume
        matchesByStrategy
        form
        include
      }
    }
  }
`;

export const QUERY_RUNNER = gql`
  query getRunner($userId: ID!, $runnerId: ID!) {
    getRunner(userId: $userId, runnerId: $runnerId) {
      _id
      systemId
      marketId 
      selectionId
      runnerName
      handicap
      sortPriority
      metadata
      status
      adjustmentFactor
      lastPriceTraded
      totalMatched
      removalDate
      spNearPrice
      spFarPrice
      spBackStakeTaken
      spLayLiabilityTaken
      actualSP
      exAvailableToBack
      exAvailableToLay
      exTradedVolume
      matchesByStrategy
      form
      include
    }
  }
`;

export const QUERY_RESULTS = gql`
  query getResults ($offset: Int, $limit: Int, $userId: ID!, $systemId: ID!, $options: String, $filter: String) {
    getResults(offset: $offset, limit: $limit, userId: $userId, systemId: $systemId, options: $options, filter: $filter ) {
      results {
      _id
      userId
      systemId {
        _id,
        systemId
      }
      betId
      customerRef
      betPlaced
      eventId
      eventName
      marketId
      marketName
      selectionId
      selectionName
      orderType
      orderStatus
      betType
      racingBetType
      persistence
      betOutcome
      betStatus
      reqPrice
      priceMatched
      priceReduced
      matchedDate
      size
      sizeMatched
      sizeRemaining
      sizeLapsed
      sizeVoided
      sizeCancelled
      sizeSettled
      settledDate
      commissionperc
      commission
      liability
      profitLoss
      returned
      wallet
      closed
      metaData
      }
      count
    }
  }
`;

export const QUERY_RESULT = gql`
  query getResult($userId: ID!, $resultId: ID!) {
    getResult(userId: $userId, resultId: $resultId) {
      _id
      systemId{
        _id,
        systemId
      }
      betId
      customerRef
      betPlaced
      eventId
      eventName
      marketId
      marketName
      selectionId
      selectionName
      orderType
      orderStatus
      betType
      racingBetType
      persistence
      betOutcome
      betStatus
      reqPrice
      priceMatched
      priceReduced
      matchedDate
      size
      sizeMatched
      sizeRemaining
      sizeLapsed
      sizeVoided
      sizeCancelled
      sizeSettled
      settledDate
      commissionperc
      commission
      liability
      profitLoss
      returned
      wallet
      closed
      metaData
    }
  }
`;

export const QUERY_API = gql`
  query getApisetting($userId: ID!) {
    getApisetting(userId: $userId) {
      _id
      userId
      apiKeyTest
      testSessionId
      apiKeyLive
      liveSessionId
      apiMode
      apiUsername
      apiPassword
      certfile
      keyfile
      lastTestLogin
      lastTestKeepAlive
      lastTestLogout
      testApiEnabled
      testApiStatus
      lastLiveLogin
      lastLiveKeepAlive
      lastLiveLogout
      liveApiEnabled
      liveApiStatus
      lastTestStatus
      lastLiveStatus
      lastTestMessage
      lastLiveMessage
    }
  }
`;

export const QUERY_SCENARIOS = gql`
query getScenarios($userId: ID!) {
    getScenarios(userId: $userId){
      _id
      scenarioId
      title
      description
      back
      lay
      params
    }
  }
`;

export const QUERY_STAKINGS = gql`
query getStakings($userId: ID!) {
    getStakings(userId: $userId){
      _id
      stakingId
      title
      description
      back
      lay
      params
    }
  }
`;

export const QUERY_EVENT_TYPES = gql`
query getEventTypes {
    getEventTypes{
      _id
      eventTypeId
      name
      used
    }
  }
`;

export const QUERY_TRANSACTION = gql`
query getTransaction($userId: ID!, $systemId: ID, $transactionId: ID, $account: String) {
  getTransaction(userId: $userId, systemId: $systemId, transactionId: $transactionId, account: $account){
      _id
      userId
      systemId
      transactionType
      transactionDesc
      account
      amt
      balance
    }
  }
`;

export const QUERY_TRANSACTIONS = gql`
query getTransactions($userId: ID!, $systemId: ID, $account: String) {
  getTransactions(userId: $userId, systemId: $systemId, account: $account){
      _id
      userId
      systemId
      transactionType
      transactionDesc
      account
      amt
      balance
    }
  }
`;