import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../logo.png';
import BSModal from '../../BSModal';
import Auth from '../../../utils/auth';
import { Person } from 'react-bootstrap-icons';
import { useQuery, useMutation } from '@apollo/client';
import { QUERY_TRANSACTION } from '../../../utils/queries';
import { TRANSACTION } from '../../../utils/mutations';

const MainHeader = () => {

  let navigate = useNavigate();

  let userId = '';
  if (Auth.loggedIn()) {
    const user = Auth.getProfile();
    userId = user.data._id;
  }

  const formatCur = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD'
  });

  // State to control modal
  const [modalState, setModalState] = useState({ show: false });

  // Do account transaction mutation
  const [transaction] = useMutation(TRANSACTION,
    {
      refetchQueries: [
        'getTransaction'
      ],
    });

  const { data: dataTV } = useQuery(QUERY_TRANSACTION,
    {
      variables: { userId, account: 'virtual' },
      pollInterval: 60000,
    });

  const { data: dataTL } = useQuery(QUERY_TRANSACTION,
    {
      variables: { userId, account: 'live' },
      pollInterval: 60000,
    });

  const logout = (event) => {
    event.preventDefault();
    Auth.logout();
    navigate("login");
  };

  const profile = (event) => {
    event.preventDefault();
    navigate("profile");
  };

  const wallet = (event) => {
    event.preventDefault();
    let newModalState = {
      title: 'Set Virtual Wallet',
      description: 'Enter in the amount you wish to set the wallet to.',
      buttons: [
        {
          variant: 'success',
          name: 'setWallet',
          title: 'Ok'
        },
        {
          variant: 'secondary',
          name: 'cancel',
          title: 'Cancel'
        }
      ],
      form:
      {
        fields: [
          {
            type: 'text',
            name: 'wallet',
          }
        ]
      }
      ,
      show: true,
      data: '' // Pass through any data if needed
    }
    //Update modal state
    setModalState(newModalState);
  };

  //Handle modal btn click
  const handleModalClick = async (event, formState, data) => {
    event.preventDefault();
    const action = event.target.name;

    if (action === 'cancel') { setModalState({ show: false }) };

    if (action === 'setWallet') {
      const amt = parseInt(((formState.wallet - virtualWallet) * 100).toFixed(0));
      const transactionDesc = "Set virtual wallet adjustment";
      const account = 'virtual';

      const response = await transaction({
        variables: { userId, transactionDesc, account, amt },
      });

      if (response.data.transaction && response.data.transaction.status === true) {
        //Update success
        let newModalState = {
          title: 'Update Success',
          description: response.data.transaction.msg,
          buttons: [
            {
              variant: 'secondary',
              name: 'cancel',
              title: 'Ok'
            }
          ],
          show: true,
          data: '' // Pass through any data if needed
        }
        //Update modal state
        setModalState(newModalState);
      } else {
        //Update failure
        let newModalState = {
          title: 'Update Failed',
          description: response.data.transaction.msg,
          buttons: [
            {
              variant: 'secondary',
              name: 'cancel',
              title: 'Ok'
            }
          ],
          show: true,
          data: '' // Pass through any data if needed
        }
        //Update modal state
        setModalState(newModalState);
      }
    }
  };

  const virtualTrans = dataTV?.getTransaction || {};
  const liveTrans = dataTL?.getTransactions || {};

  let virtualWallet = (0).toFixed(2);
  let liveWallet = (0).toFixed(2);
  if (virtualTrans.balance) virtualWallet = (virtualTrans.balance / 100).toFixed(2);
  if (liveTrans.balance) liveWallet = (liveTrans.balance / 100).toFixed(2);


  return (
    <header className="container">
      <div className="row">
        <div className="d-inline">
          <Link to="/" className="nolink"><img src={logo} alt="Logo" /></Link>
          <h5>Welcome {Auth.getProfile().data.firstname}!</h5>
          <div className="d-inline float-end">
            <div className="d-inline btn mr-3">Live Wallet: <span className="wallet-display">{formatCur.format(liveWallet)}</span></div>
            <div className="d-inline mr-3"><button className="btn font-weight-bold" type="button" name="virtual" onClick={wallet}>Virtual Wallet: <span className="wallet-display">{formatCur.format(virtualWallet)}</span></button></div>
            <button className="btn" onClick={profile} title="My Profile"><Person size={30} /></button>
            <button className="btn btn-sm btn-secondary mb-1" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>
      <BSModal modalState={modalState} handleModalClick={handleModalClick} />
    </header >
  );
};

export default MainHeader;
