import React, { Suspense } from 'react';
import './App.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Support from './pages/Support';
import Usage from './pages/Usage';
import Header from './components/Header';
import Footer from './components/Footer';
import Nav from './components/Nav';
import { ErrorBoundary } from 'react-error-boundary';
import Error from './components/Error';
import { ErrorHandler } from './utils/helpers';
import Auth from './utils/auth';

const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Signup = React.lazy(() => import('./pages/Signup'));
const Login = React.lazy(() => import('./pages/Login'));
const Profile = React.lazy(() => import('./pages/Profile'));
const Systems = React.lazy(() => import('./pages/Systems'));
const System = React.lazy(() => import('./pages/System'));
const Events = React.lazy(() => import('./pages/Events'));
const Market = React.lazy(() => import('./pages/Market'));
const Results = React.lazy(() => import('./pages/Results'));
const Result = React.lazy(() => import('./pages/Result'));
const Api = React.lazy(() => import('./pages/Api'));

// Main GraphQL API endpoint
const httpLink = createHttpLink({
  uri: 'https://app.betterbetbot.com.au/api',
});

// Construct request middleware that will attach the JWT token to every request as an `authorization` header
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('id_token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  // Set up our client to execute the `authLink` middleware prior to making the request to the GraphQL API
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

interface FallbackProps {
  error: Error
  resetErrorBoundary: (...args: Array<unknown>) => void
}

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <>
      <Error error={error} resetErrorBoundary={resetErrorBoundary} />
    </>
  )
}

function App() {

  return (
    <ApolloProvider client={client}>
      <Router>
        <div className="container">
          <Header />
          <Nav />
          <div className="container">
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={ErrorHandler}>
              <Suspense fallback={
                <div className="d-flex justify-content-center m-3">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              }>
                <Routes>
                  <Route
                    path="/"
                    element={<Dashboard />}
                  />
                  <Route
                    path="/login/"
                    element={<Login />}
                  />
                  <Route
                    path="/signup/"
                    element={<Signup />}
                  />
                  <Route
                    path="/profile/"
                    element={<Profile />}
                  />
                  <Route
                    path="/systems/"
                    element={<Systems />}
                  />
                  <Route
                    path="/system/:systemId"
                    element={<System />}
                  />
                  <Route
                    path="/events/"
                    element={<Events />}
                  />
                  <Route
                    path="/events/:systemId"
                    element={<Events />}
                  />
                  <Route
                    path="/market/:marketId/:systemId"
                    element={<Market />}
                  />
                  <Route
                    path="/results/"
                    element={<Results />}
                  />
                  <Route
                    path="/results/:systemId"
                    element={<Results />}
                  />
                  <Route
                    path="/result/:resultId"
                    element={<Result />}
                  />
                  <Route
                    path="/api/"
                    element={<Api />}
                  />
                  <Route
                    path="/support/"
                    element={<Support />}
                  />
                  <Route
                    path="/usage/"
                    element={<Usage />}
                  />
                  <Route
                    path="*"
                    element={<Dashboard />}
                  />
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </div>
          <Footer />
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
