import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

const Error = ({ error, resetErrorBoundary }) => {
  //General app error notification content
  //Capture authenticaton error
  if (error.message === 'Authentication error.' || error.message === 'Username or password error') {
    return (
      <main>
        <section className="container my-2">
          <Alert show={true} variant="danger">
            <p>
              Username or password error or not logged in. If error pesists, please contact support.
            </p>
            <div>
              <Button onClick={resetErrorBoundary} variant="outline-success">
                Try Again
              </Button>
            </div>
          </Alert>
        </section>
      </main >
    )
  } else {

    return (
      <main>
        <section className="container my-2">
          <Alert show={true} variant="danger">
            <div>
              <Alert.Heading>Sorry, something went wrong.</Alert.Heading>
              <p>
                If error pesists, please contact support.
              </p>
            </div>
            <div>
              <Button onClick={resetErrorBoundary} variant="outline-success">
                Try Again
              </Button>
            </div>
          </Alert>
        </section>
      </main >
    )
  }
};

export default Error;


