import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  mutation loginUser($username: String!, $password: String!) {
    loginUser(username: $username, password: $password) {
      token
      user {
        _id
        username
      }
      status
      msg
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($username: String!, $email: String!, $password: String!, $firstname: String!, $lastname: String!) {
    createUser(username: $username, email: $email, password: $password, firstname: $firstname, lastname: $lastname) {
      token
      user {
        _id
        username
      }
      status
      msg
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userId: ID!, $username: String!, $email: String!, $mobile: String!, $firstname: String!, $lastname: String!
    $wallet: Int, $virtualWallet: Int, $maxWallet: Int, $minWallet: Int, $maxVirtualWallet: Int, $minVirtualWallet: Int, $billingIsStreet: Boolean) {
    updateUser(
    userId: $userId,
    username: $username, 
    email: $email,
    mobile: $mobile,
    firstname: $firstname,
    lastname: $lastname, 
    wallet: $wallet,
    virtualWallet: $virtualWallet,
    maxWallet: $maxWallet,
    minWallet: $minWallet,
    maxVirtualWallet: $maxVirtualWallet,
    minVirtualWallet: $minVirtualWallet,
    billingIsStreet: $billingIsStreet
      ) {
    status
    msg
    }
  }
`;

export const TRANSACTION = gql`
  mutation transaction($userId: ID!, $systemId: ID, $transactionDesc: String!, $account: String!, $amt: Int!) {
    transaction(
    userId: $userId,
    systemId: $systemId
    transactionDesc: $transactionDesc
    account: $account
    amt: $amt
      ) {
    status
    msg
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($userId: ID!, $_id: ID!, $addressType: String!, $address1: String, $address2: String, $city: String, $state: String, $postalCode: String, $country: String ) {
    updateAddress(userId: $userId, _id: $_id, addressType: $addressType, address1: $address1, address2: $address2, city: $city, state: $state, postalCode: $postalCode, country: $country) {
    status
    msg
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($userId: ID!, $password: String!, $newpassword: String!) {
    updatePassword(userId: $userId, password: $password, newpassword: $newpassword) {
    status
    msg
    }
  }
`;

export const UPDATE_API = gql`
  mutation updateApi($userId: ID!, $apiKeyTest: String, $testSessionId: String, $apiKeyLive: String, $liveSessionId: String, 
    $apiUsername: String, $apiPassword: String, $certfile: String, $keyfile: String)
    {
    updateApi(apiKeyTest: $apiKeyTest, testSessionId: $testSessionId, apiKeyLive: $apiKeyLive, liveSessionId: $liveSessionId, 
    apiUsername: $apiUsername, apiPassword: $apiPassword, certfile: $certfile, keyfile: $keyfile)
    {
      status
      msg
    }
  }
`;

export const ENABLE_API = gql`
  mutation enableApi($userId: ID!, $apiType: String!) {
    enableApi(userId: $userId, apiType: $apiType) {
      status
      msg
    }
  }
`;

export const DISABLE_API = gql`
  mutation disableApi($userId: ID!, $apiType: String!) {
    disableApi(userId: $userId, apiType: $apiType) {
      status
      msg
    }
  }
`;

export const TEST_API = gql`
  mutation testApi($userId: ID!, $apiType: String!) {
    testApi(userId: $userId, apiType: $apiType) {
      status
      msg
    }
  }
`;

export const API_LOGIN = gql`
  mutation apiLogin($userId: ID!, $apiType: String!) {
    apiLogin(userId: $userId, apiType: $apiType) {
      status
      msg
    }
  }
`;

export const API_LOGOUT = gql`
  mutation apiLogout($userId: ID!, $apiType: String!) {
    apiLogout(userId: $userId, apiType: $apiType) {
      status
      msg
    }
  }
`;

export const TOGGLE_SYSTEM = gql`
  mutation toggleSystem($userId: ID!, $systemId: ID!, $toggle: String!, $getEvents: Boolean!, $apiType: String!) {
    toggleSystem(userId: $userId, systemId: $systemId, toggle: $toggle,  getEvents:$getEvents, apiType: $apiType) {
      status
      msg
    }
  }
`;

export const CREATE_SYSTEM = gql`
  mutation createSystem($userId: ID!) {
    createSystem(userId: $userId) {
      status
      msg
    }
  }
`;

export const UPDATE_SYSTEM = gql`
  mutation updateSystem(
    $_id: ID!
    $userId: ID!
    $action: String!
    $title: String
    $description: String
    $isRacingEvent: Boolean
    $scenario: ID
    $stakingPlan: ID
    $scenarioParams: String
    $stakingParams: String
    $mode: String
    $apiMode: String
    $betType: String
    $racingBetType: String
    $raceNumbers: String
    $raceDistance: String
    $raceClass: String
    $matchInPlay: Boolean
    $maxEvents: Int
    $maxMarkets: Int
    $minOdds:Int
    $maxOdds: Int
    $maxRunners: Int
    $minRunners: Int
    $maxBackLayRatio: Int
    $maxLiability: Int
    $maxBets: Int
    $minBets: Int
    $minMatched: Int
    $maxBookBackBets: Int
    $minBookLayBets: Int
    $minPlaceWinners: Int
    $maxPlaceWinners: Int
    $stopLoss: Int
    $stopProfit: Int
    $maxLosers: Int
    $maxWinners: Int
    $consecLosers: Int
    $consecWinners: Int
    $betStartingPrice: Boolean
    $unsettledLimit: Int
    $includeCommission: Boolean
    $timeSecsRace: Int
    $nthFavourite: Int
    $numFavourites: Int
    $exclFavourite: Int
    $totalEvents: Int
    $totalMarkets: Int
    $totalBets: Int
    $profitLoss: Int
    $totalLosers: Int
    $totalWinners: Int
    $totalConsecLosers: Int
    $totalConsecWinners: Int
    $maxConsecLosers: Int
    $maxConsecWinners: Int
    $lossStopOption: String
    $profitStopOption: String
    $totalBetsOption: String
    $totalEventsOption: String
    $totalMarketsOption: String
    $maxWinnersOption: String
    $maxLosersOption: String
    $consecWinnersOption: String
    $consecLosersOption: String
    $unsettledBets: Int
    $maxBet: Int
    $textQuery: String
    $eventTypeId: String
    $competitionIds: String
    $marketIds: String
    $venues: String
    $bspOnly: Boolean
    $turnInPlayEnabled: Boolean
    $inPlayOnly: Boolean
    $marketBettingTypes: String
    $marketCountries: String
    $marketTypeCodes: String
    $customTime: Boolean
    $marketFromTime: Date
    $marketToTime: Date
    $withOrders: String
    $raceTypes: String
    $sort: String
    $maxResults: Int
    $marketProjection: String
    $statusDesc: String
    $resultFilter: String
    $ordering: Int
  ) {
    updateSystem(
    _id: $_id,
    userId: $userId,
    action: $action,
    title: $title,
    description: $description,
    isRacingEvent: $isRacingEvent,
    scenario: $scenario,
    stakingPlan: $stakingPlan,
    scenarioParams: $scenarioParams,
    stakingParams: $stakingParams,
    mode: $mode,
    apiMode: $apiMode,
    betType: $betType,
    racingBetType: $racingBetType,
    raceNumbers: $raceNumbers,
    raceDistance: $raceDistance,
    raceClass: $raceClass,
    matchInPlay: $matchInPlay,
    maxEvents: $maxEvents,
    maxMarkets: $maxMarkets,
    minOdds:$minOdds,
    maxOdds: $maxOdds,
    maxRunners: $maxRunners,
    minRunners: $minRunners,
    maxBackLayRatio: $maxBackLayRatio,
    maxLiability: $maxLiability,
    maxBets: $maxBets,
    minBets: $minBets,
    minMatched: $minMatched,
    maxBookBackBets: $maxBookBackBets,
    minBookLayBets: $minBookLayBets,
    minPlaceWinners: $minPlaceWinners,
    maxPlaceWinners: $maxPlaceWinners,
    stopLoss: $stopLoss,
    stopProfit: $stopProfit,
    maxLosers: $maxLosers,
    maxWinners: $maxWinners,
    consecLosers: $consecLosers,
    consecWinners: $consecWinners,
    betStartingPrice: $betStartingPrice,
    unsettledLimit: $unsettledLimit,
    includeCommission: $includeCommission,
    timeSecsRace: $timeSecsRace,
    nthFavourite: $nthFavourite,
    numFavourites: $numFavourites,
    exclFavourite: $exclFavourite,
    totalEvents: $totalEvents,
    totalMarkets: $totalMarkets,
    totalBets: $totalBets,
    profitLoss: $profitLoss,
    totalLosers: $totalLosers,
    totalWinners: $totalWinners,
    totalConsecLosers: $totalConsecLosers,
    totalConsecWinners: $totalConsecWinners,
    maxConsecLosers: $maxConsecLosers,
    maxConsecWinners: $maxConsecWinners,
    lossStopOption: $lossStopOption,
    profitStopOption: $profitStopOption,
    totalBetsOption: $totalBetsOption,
    totalEventsOption: $totalEventsOption,
    totalMarketsOption: $totalMarketsOption,
    maxWinnersOption: $maxWinnersOption,
    maxLosersOption: $maxLosersOption,
    consecWinnersOption: $consecWinnersOption,
    consecLosersOption: $consecLosersOption,
    unsettledBets: $unsettledBets,
    maxBet: $maxBet,
    textQuery: $textQuery,
    eventTypeId: $eventTypeId,
    competitionIds: $competitionIds,
    marketIds: $marketIds,
    venues: $venues,
    bspOnly: $bspOnly,
    turnInPlayEnabled: $turnInPlayEnabled,
    inPlayOnly: $inPlayOnly,
    marketBettingTypes: $marketBettingTypes,
    marketCountries: $marketCountries,
    marketTypeCodes: $marketTypeCodes,
    customTime: $customTime,
    marketFromTime: $marketFromTime,
    marketToTime: $marketToTime,
    withOrders: $withOrders,
    raceTypes: $raceTypes,
    sort: $sort,
    maxResults: $maxResults,
    marketProjection: $marketProjection,
    statusDesc: $statusDesc,
    resultFilter: $resultFilter,
    ordering: $ordering ,
    ) {
      status
      msg
    }
  }
`;

export const RESET_SYSTEM = gql`
  mutation resetSystem($userId: ID!, $systemId: ID!) {
    resetSystem(userId: $userId, systemId: $systemId) {
      status
      msg
    }
  }
`;

export const COPY_SYSTEM = gql`
  mutation copySystem($userId: ID!, $systemId: ID!) {
    copySystem(userId: $userId, systemId: $systemId) {
      status
      msg
    }
  }
`;

export const TOGGLE_ARCHIVE_SYSTEM = gql`
  mutation archiveSystem($userId: ID!, $systemId: ID!, $toggle: String!) {
    archiveSystem(userId: $userId, systemId: $systemId, toggle:$toggle) {
      status
      msg
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent($userId: ID!, $systemId: ID!, $eventId: ID!, $include: Boolean) {
    updateEvent(userId: $userId, systemId: $systemId, eventId: $eventId, include: $include) {
      status
      msg
    }
  }
`;

export const UPDATE_MARKET = gql`
  mutation updateMarket($userId: ID!, $systemId: ID!, $marketId: ID!, $include: Boolean) {
    updateMarket(userId: $userId, systemId: $systemId, marketId: $marketId, include: $include) {
      status
      msg
    }
  }
`;

export const UPDATE_RUNNER = gql`
  mutation updateRunner($userId: ID!, $systemId: ID!, $runnerId: ID!, $include: Boolean) {
    updateRunner(userId: $userId, systemId: $systemId, runnerId: $runnerId, include: $include) {
      status
      msg
    }
  }
`;

export const LOAD_EVENTS = gql`
  mutation loadEvents($userId: ID!, $systemId: ID!) {
    loadEvents(userId: $userId, systemId: $systemId) {
      status
      msg
    }
  }
`;