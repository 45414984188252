import { Link } from 'react-router-dom';
import logo from '../../../logo.png';

const LoginHeader = () => {

  return (
    <header className="container align-center">
      <div className="row">
        <div className="d-inline">
          <Link to="/" className="nolink"><img src={logo} alt="Logo" /></Link>
          <h6> Fully featured online web app providing automated betting systems for Betfair users.</h6>
        </div>
      </div>
    </header >
  );
};

export default LoginHeader;
