import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function BSmodal(props) {

  const modalState = props.modalState;
  const handleModalClick = props.handleModalClick;

  //Set initial form state
  const [formState, setFormState] = useState(modalState.form ? modalState.form.fields : '');

  //Update form state based on form input changes
  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  }

  const handleSubmit = async (event) => {
    const data = props.modalState.data;
    handleModalClick(event, formState, data);
  }

  return (
    <>
      <Modal
        show={modalState.show}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title >{modalState.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {modalState.description}
          {modalState.form ? modalState.form.fields.map((field, i) => {
            return (
              <input key={i} type={field.type} id={field.name} name={field.name} value={formState.wallet} onChange={handleChange}></input>
            )
          }) : ''}
        </Modal.Body>
        <Modal.Footer >
          {modalState.buttons ? modalState.buttons.map((button, i) => {
            return <Button key={i} type="button" variant={button.variant} name={button.name} onClick={handleSubmit}>{button.title}</Button>
          }) : ''}
        </Modal.Footer>
      </Modal>
    </>
  );
}